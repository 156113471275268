import { Controller } from "@hotwired/stimulus"
import { get, post } from "@rails/request.js"
import { getElementById } from "domutils";


export default class extends Controller {
  static targets = ["productSelect", "quantityInput", "saleTable", "form", "discountValue"]
  static values = {
    id: String
  }
  category_select(event) {
    let target = this.productSelectTarget.id;
    let category = event.target.selectedOptions[0].value;

    get(`/admin/product_categories/products?target=${target}&product_category=${category}`, {
      responseKind: "turbo-stream"
    })
  }

  showDiscountModal({ params: { booking, discount } }) {
    document.getElementById('modal-booking').value = booking
    this.discountValueTarget.value = discount
    $('#bookings-discount-modal').modal('show')
  }

  submitDiscount(event) {
    $('#bookings-discount-modal').modal('hide')
  }

  addProduct(event) {
    event.preventDefault();
    let product = this.productSelectTarget.selectedOptions[0].value
    let quantity = this.quantityInputTarget.value
    let table = this.saleTableTarget
    let form = this.formTarget

    // let saleForm = {
    //   'product_id': form.querySelector("[name='sale[product]']").value,
    //   'quantity': form.querySelector("[name='sale[quantity]']").value
    // }

    let formData = new FormData();
    formData.append('authenticity_token', form.querySelector("[name='authenticity_token']").value);
    formData.append('sale[product_id]', form.querySelector("[name='sale[product]']").value);
    formData.append('sale[quantity]', form.querySelector("[name='sale[quantity]']").value);
    formData.append('sale[bill_id]', form.querySelector("[name='sale[bill_id]']").value);

    fetch(form.action, {
      method: 'POST',
      body: formData
    }).then(response => {
      return response.json();
    }).then(data => {
      // console.log(data)
      let row = table.insertRow(-1)
      row.insertCell(0).appendChild(document.createTextNode(data.name))
      row.insertCell(1).appendChild(document.createTextNode(data.quantity))
      row.insertCell(2).appendChild(document.createTextNode(data.amount))
    }).catch((error) => {
      console.error('Error:', error);
    });
  }
}