import { Controller } from "@hotwired/stimulus"
import "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es"

export default class extends Controller {
  static targets = ["form", "change", "bill", "cashContainer", "discountType", "productWrapper",
    "roomCategoryWrapper", "loader", "paymentTypeOne", "paymentTypeTwo", "paymentOne", "paymentTwo", "paymentTotal"]

  initialize() {
    let calendars = document.getElementsByClassName("flatpickr");
    Array.prototype.forEach.call(calendars, function (element) {
      flatpickr(element, {
        locale: Spanish,
        dateFormat: "d/m/Y",
        disableMobile: "true"
        // minDate: new Date()
      });
    });
    if (this.hasDiscountTypeTarget && this.hasRoomCategoryWrapperTarget && this.hasProductWrapperTarget) {
      this.selectDiscountType();
    }
  }

  // Add controller to parent element and target the form else it will default as the controller was added to the form
  submitForm(event) {
    if (this.hasFormTarget) {
      this.formTarget.submit()
    } else {
      this.element.submit()
    }
  }

  // Formats rut as the user inputs
  formatRut(event) {
    let rut = event.target.value.replace(/\.| /g, '').replace('-', '');
    if (rut.match(/^(\d{1,2})(\d{3})(\d{3})([k \d])$/)) {
      rut = rut.replace(/^(\d{1,2})(\d{3})(\d{3})([k \d])$/, '$1.$2.$3-$4');
    }
    else if (rut.match(/^(\d)(\d{3})(\d{1,3})$/)) {
      rut = rut.replace(/^(\d)(\d{3})(\d{1,3})$/, '$1.$2.$3');
    }
    else if (rut.match(/^(\d)(\d{1,3})$/)) {
      rut = rut.replace(/^(\d)(\d{1,3})$/, '$1.$2');
    } else if (rut.match(/^(\d)$/)) {

    } else {
      rut = event.target.value.slice(0, -1)
    }
    event.target.value = rut;
  }

  // checkif user selected cash in payment type select
  isCash(event) {
    if (event.target.options[event.target.selectedIndex].text.toLowerCase().includes("efectivo")) {
      if (this.hasCashContainerTarget) {
        this.cashContainerTarget.classList.toggle("d-none")
      }
    } else if (this.hasCashContainerTarget && !this.cashContainerTarget.classList.contains("d-none")) {
      this.cashContainerTarget.classList.add("d-none")
    }
  }

  // Calculates cash change from inputs
  calculateChange(event) {
    if (this.hasChangeTarget && this.hasBillTarget) {
      let cash = parseFloat(event.target.value.replace(/[$ .,]/g, ""));
      let bill = parseFloat(this.billTarget.value.replace(/[$ .,]/g, ""));
      let change = cash - bill;
      if (change > 0) {
        this.changeTarget.value = formatter.format(change);
      } else {
        this.changeTarget.value = "";
      }
    }
  }

  selectDiscountType(_event) {
    if (this.hasRoomCategoryWrapperTarget && this.hasProductWrapperTarget) {
      this.showLoader();
      this.productWrapperTarget.classList.add("d-none")
      this.roomCategoryWrapperTarget.classList.add("d-none")
    }
    setTimeout(() => {
      if (this.hasDiscountTypeTarget && this.hasRoomCategoryWrapperTarget && this.hasProductWrapperTarget) {
        switch (this.discountTypeTarget.value) {
          case 'room_category':
            this.roomCategoryWrapperTarget.classList.remove("d-none")
            this.productWrapperTarget.getElementsByTagName('select')[0].selectedIndex = 0
            break;
          case 'product':
            this.productWrapperTarget.classList.remove("d-none")
            this.roomCategoryWrapperTarget.getElementsByTagName('select')[0].selectedIndex = 0
            break;
        }
      }
      this.hideLoader();
    }, 600)
  }

  dividePayment(event) {
    // let text = event.target[event.target.value].text;
    // console.log(text)
    // if (text.toLowerCase().includes('efectivo')) {
    //   if (this.hasCashContainerTarget) {
    //     this.cashContainerTarget.classList.toggle("d-none")
    //   }
    // }
    if (event.target.dataset.formTarget == "paymentTypeOne") {

    } else if (event.target.dataset.formTarget == "paymentTypeTwo") {

    }
  }

  changePayment(event) {
    let amount = event.target.value;
    this.paymentTwoTarget.value = this.paymentTotalTarget.value - amount;
  }

  showLoader() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.add("loader")
    }
  }

  hideLoader() {
    if (this.hasLoaderTarget) {
      this.loaderTarget.classList.remove("loader")
    }
  }
}

let formatter = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
});