import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['periodSelect', 'startDate', 'endDate']
  static values = {
    graph: Boolean
  }

  connect() {
    this.checkPeriod()
  }

  checkPeriod(event) {
    if (this.hasPeriodSelectTarget && this.periodSelectTarget.value === 'hour' && this.hasEndDateTarget) {
      this.endDateTarget.classList.add('d-none')
      this.endDateTarget.disabled = true
    } else if (this.hasEndDateTarget) {
      this.endDateTarget.classList.remove('d-none')
      this.endDateTarget.disabled = false
    }
  }
}