import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {
  static targets = ['type', 'message']

  connect() {
    Swal.fire({
      title: this.messageTarget.value,
      icon: this.getNotificationType(),
      toast: true,
      position: 'top-end',
      timer: 6000,
      timerProgressBar: true,
      showConfirmButton: false,
    })
  }

  getNotificationType() {
    switch (this.typeTarget.value) {
      case 'info':
        return 'info'
      case 'success':
        return 'success'
      case 'error':
        return 'error'
      case 'alert':
        return 'warning'
      default:
        return 'info'
    }
  }
}