import { Controller } from "@hotwired/stimulus"
import Swal from 'sweetalert2'

export default class extends Controller {

  assert_modal(event) {
    event.preventDefault();
    Swal.fire({
      title: `¿Esta seguro?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        // console.log(event.target.firstElementChild)
        event.target.firstElementChild.submit()
      }
    })
  }

  toggle(event) {
    event.preventDefault();
    let el = document.getElementById(event.params.id)
    document.querySelectorAll('.show').forEach(function (elem) {
      if (el != elem) {
        elem.classList.toggle('hidden')
        elem.classList.toggle('show')
      }
    })
    el.classList.toggle('hidden')
    el.classList.toggle('show')
  }
}