import { Controller } from "@hotwired/stimulus"


export default class extends Controller {
  static targets = ["add_item", "template", "add_button", "original_input"]

  addAssociation(event) {
    event.preventDefault()
    let templateElement
    if (event.target.dataset.template)
      templateElement = document.getElementById(event.target.dataset.template)
    else
      templateElement = this.templateTarget

    let content = templateElement.innerHTML.replace(/TEMPLATE_RECORD/g, new Date().valueOf())
    this.add_itemTarget.insertAdjacentHTML('beforebegin', content)
    let maximum = this.data.get("maximum")
    let current = parseInt(this.data.get("current")) || 0
    current += 1;
    if (maximum) {
      this.data.set("current", current)
      if (current == maximum) {
        this.add_buttonTarget.style.display = 'none'
        this.original_inputTarget.remove()
      }
    }
  }

  removeAssociation(event) {
    event.preventDefault()
    const wrapper = event.target.closest(".nested-fields")
    if (wrapper.dataset.newRecord === 'true') {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = '1'
      wrapper.style.display = 'none'
    }
  }
}