import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"
export default class extends Controller {
  static targets = ["url", "room", "loader", "hotelSelect", "roomCategorySelect"]
  static classes = ["loading"]

  initialize() {
    if (this.hasRoomCategorySelectTarget) {
      this.getRoomCategory();
    }
  }

  room_today(event) {
    this.loaderTarget.classList.add(this.loadingClass)
    const url = event.target.dataset.url
    const room = event.target.dataset.room
    this.roomTarget.innerHTML = `Reservas Habitación ${room}`
    fetch(url)
      .then(response => response.text())
      .then(html => {
        this.loaderTarget.classList.remove(this.loadingClass)
        this.urlTarget.innerHTML = html
      })
  }

  selectHotel(event) {
    let target = this.roomCategorySelectTarget.id
    let hotel = event.target.selectedOptions[0].value
    get(`/api/hotels/room_categories?target=${target}&hotel=${hotel}`, {
      responseKind: "turbo-stream"
    })
  }

  getRoomCategory(event) {
    let target = this.roomCategorySelectTarget.id
    let hotel
    if (this.hotelSelectTarget.nodeName == 'SELECT') {
      hotel = this.hotelSelectTarget.selectedOptions[0].value
    } else {
      hotel = this.hotelSelectTarget.value
    }
    get(`/api/hotels/room_categories?target=${target}&hotel=${hotel}`, {
      responseKind: "turbo-stream"
    })
  }
}
