import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    let els = document.getElementsByClassName("nested-fields")
    Array.prototype.forEach.call(els, function (el) {
      if (el.dataset.newRecord != 'true') {
        let productId = el.querySelector('select').value
        if (productId) {
          let url = `/api/products/${productId}/cost`
          fetch(url)
            .then(response => response.json())
            .then(data => {
              let cost = formatter.format(data.cost)
              let quantity = el.getElementsByClassName("purchase_quantity")[0].value
              el.querySelector("input[name*='cost']").value = cost
              el.querySelector("input[name*='new_cost']").value = cost
              el.getElementsByClassName("purchase_amount")[0].value = data.cost * quantity
            })
        }
      }
    });
  }
  getCost(event) {
    let productId = event.target.value
    if (productId) {
      let url = `/api/products/${productId}/cost`
      fetch(url)
        .then(response => response.json())
        .then(data => {
          let cost = formatter.format(data.cost)
          let item = event.target.closest(".nested-fields")
          item.querySelector("input[name*='cost']").value = cost
        })
    } else {
      event.target.closest(".nested-fields").querySelector("input[name*='cost']").value = ""
    }
  }

  setNewCost(event) {
    let nestedFields = event.target.closest(".nested-fields")
    let quantity = nestedFields.querySelector("input[name*='quantity']").value
    let amount = nestedFields.querySelector("input[name*='amount']").value
    if (quantity != "" && amount != "") {
      let newCostEl = nestedFields.querySelector("input[name*='new_cost']")
      let newCost = parseFloat(amount) / parseFloat(quantity)
      newCostEl.value = formatter.format(newCost)
    }
  }
}

let formatter = new Intl.NumberFormat('es-CL', {
  style: 'currency',
  currency: 'CLP',
});